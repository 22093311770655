/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search-green': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<g transform="translate(1 1)" _stroke="#154F4A" stroke-width="1.5" _fill="none" fill-rule="evenodd"><circle pid="0" cx="6" cy="6" r="6"/><path pid="1" d="M11 12l3.094 3.341"/></g>'
  }
})
